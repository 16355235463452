import { Injectable } from "@angular/core";
import { BehaviorSubject, filter, firstValueFrom } from "rxjs";

@Injectable({providedIn: "root"})
export class CAttachPanelService {
    public active: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public robot_name: string = null;
    public robot_id: number = null; 

    public async attach(robot_name: string): Promise<number> {        
        this.robot_name = robot_name;
        this.robot_id = null;
        this.active.next(true);
        await firstValueFrom(this.active.pipe(filter(a => !a))); // ждем закрытия
        return this.robot_id; // после всех шагов панель вернет ID подписки, которую привяжем к прикрепляемому роботу
    }
}
